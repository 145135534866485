import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";
import {
  encryptKeywords,
  decryptContent,
  encryptContent,
  decryptSymmetricKey,
} from "../utils/cryptoHelpers";

const preSaveMod = async (data, note) => {
  if (!data || !note) {
    return data;
  }
  let encryptedSymmetricKey = note?.encryptedSymmetricKey;
  const symmetricKey = await decryptSymmetricKey(encryptedSymmetricKey);
  const model = { ...data };
  if (model.keywords?.trim() > "") {
    const { encryptedContent, iv } = await encryptContent(
      model.keywords.trim(),
      symmetricKey
    );
    let tokens = model.keywords?.trim()?.toLowerCase()?.split(",");

    model.keywords = encryptedContent;
    model.keywordTokens = await encryptKeywords(tokens);
    model.keywordIv = iv;
  }
  return model;
};
export const postLoadMod = async (data, note, key) => {
  let symmetricKey = key;
  if (!data || !note) {
    return data;
  }
  console.log("postLoadMod", data);
  const model = { ...data };
  console.log("keywords", model.keywords);
  if (!symmetricKey) {
    let encryptedSymmetricKey = note?.encryptedSymmetricKey;
    symmetricKey = await decryptSymmetricKey(encryptedSymmetricKey);
  }
  if (model.keywords && model.keywordIv) {
    model.keywords = await decryptContent(
      model.keywords,
      model.keywordIv,
      symmetricKey
    );
  }

  return model;
};

export function useFileStore() {
  const isLoading = ref(false);
  const files = computed(() => store.state.files);

  async function fetchFiles(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/files", { params: query });
      store.mutations.setState("files", response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function updateFile(id, fileData, noteId) {
    try {
      let note = store.state.notes?.find((note) => note.id === noteId);
      const encrypted = await preSaveMod(fileData, note);
      const response = await api.put(`/api/files/${id}`, encrypted);
      note = store.state.notes?.find((note) => note.id === noteId);
      const decrypted = await postLoadMod(response.data, note);
      store.mutations.updateModel("files", id, decrypted);
      return decrypted;
    } catch (error) {
      console.error("Error updating file:", error);
      throw error;
    }
  }

  async function getFileEditUrl(fileId) {
    try {
      const response = await api.get(`/api/files/${fileId}/edit`);
      // store.mutations.addModel("files", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching file by id:", error);
      throw error;
    }
  }

  async function fetchFileById(fileId) {
    try {
      const response = await api.get(`/api/files/${fileId}`);
      const decrypted = (await postLoadMod([response.data]))[0];
      store.mutations.addModel("files", decrypted);
      return response.data;
    } catch (error) {
      console.error("Error fetching file by id:", error);
      throw error;
    }
  }

  async function searchFiles(query) {
    try {
      const response = await api.get("/api/files/search", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching files:", error);
      throw error;
    }
  }

  async function createNoteFile(fileData, noteId) {
    try {
      let note = store.state.notes?.find((note) => note.id === noteId);
      const response = await api.post(`/api/notes/${noteId}/files`, {
        files: fileData,
      });
      if (response.data[0]) {
        const decrypted = await postLoadMod(response.data[0], note);
        note = store.state.notes?.find((note) => note.id === noteId);
        if (note) {
          store.mutations.updateModel("notes", noteId, {
            ...note,
            files: [...note.files, decrypted],
          });
        }
        return decrypted;
      }
    } catch (error) {
      console.error("Error creating file:", error);
      throw error;
    }
  }

  async function deleteNoteFile(id, noteId) {
    try {
      await api.delete(`/api/notes/${noteId}/files/${id}`);
      const note = store.state.notes?.find((note) => note.id === noteId);

      if (note) {
        console.log("set mutation");
        store.mutations.updateModel("notes", noteId, {
          ...note,
          files: [...note.files.filter((f) => f.id !== id)],
        });
      }
    } catch (error) {
      console.error("Error deleting label:", error);
      throw error;
    }
  }

  return {
    files,
    isLoading,
    fetchFiles,
    // createFile,
    updateFile,
    // deleteFile,
    getFileEditUrl,
    fetchFileById,
    searchFiles,
    createNoteFile,
    deleteNoteFile,
  };
}
