import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useColorAndImage } from "../components/NoteComp/hooks/useColorAndImage";
import { useLabelStore } from "./useLabelStore";
import { useUserStore } from "./useUserStore";
import {
  dateToShortUrlFormat,
  getTemporaryCollaboratorDisplay,
  shortUrlFormatToDate,
} from "../utils/helpers";
import store from "../store";
import { getTranslations } from "@/translations.js";

const {
  sharedWithSuggestions,
  userSuggestions,
  fetchUserSuggestions,
  fetchSharedWithSuggestions,
} = useUserStore();
const { colors } = useColorAndImage();
const initialized = ref(false);
const preloaded = ref(false);
const showFilters = ref(false);

export function useFilters() {
  const { labels } = useLabelStore();

  const route = useRoute();
  const router = useRouter();
  //   const debounceTimeout = ref(null);

  const preload = async () => {
    fetchUserSuggestions({ autoLoad: true });
    fetchSharedWithSuggestions({ autoLoad: true });
  };
  const lastFilters = ref("");

  const filters = computed(() => store.state.filters);

  const sortOptions = computed(() => {
    const translations = getTranslations();
    return [
      { label: "A-Z", value: "title_asc" },
      { label: "Z-A", value: "title_desc" },
      { label: translations.newest, value: "created_at_desc" },
      { label: translations.oldest, value: "created_at_asc" },
      { label: translations.recentlyUpdated, value: "updated_at_desc" },
    ];
  });

  const toggleOptions = computed(() => {
    const translations = getTranslations();
    return [
      { name: "pinned", label: translations.pin },
      { name: "hasFile", label: translations.file },
      { name: "hasReminder", label: translations.reminder },
      { name: "hasTask", label: translations.task },
    ];
  });

  const labelOptions = computed(() =>
    labels.value?.map((l) => ({
      label: l.name,
      value: l.name,
    }))
  );

  const ownerOptions = computed(() => {
    return [
      { label: "Created by Me", value: "createdByMe" },
      { label: "Shared with Me", value: "sharedWithMe" },
      ...(userSuggestions.value
        ? userSuggestions.value.map((u) => {
            const name = getTemporaryCollaboratorDisplay(u);
            return { label: name, value: u.email || u.phone };
          })
        : []),
    ];
  });

  const userContactOptions = computed(() => {
    return [
      ...(sharedWithSuggestions.value
        ? sharedWithSuggestions.value.map((u) => {
            const name = getTemporaryCollaboratorDisplay(u);
            return { label: name, value: u.email || u.phone };
          })
        : []),
    ];
  });

  const colorOptions = [...colors.value];

  const cycleToggle = (toggle) => {
    const current = filters.value.toggles[toggle];
    store.mutations.replaceModel("filters", "toggles", {
      ...filters.value.toggles,
      [toggle]: current === true ? false : current === false ? null : true,
    });
  };

  const toggleClass = (state) => {
    return {
      "p-button-success": state === true,
      "p-button-danger": state === false,
      "p-button-outlined": state === null,
    };
  };

  const isAnyFilterActive = computed(() => {
    return (
      filters.value.search ||
      filters.value.sort !== "created_at_desc" ||
      Object.values(filters.value.toggles).some((toggle) => toggle !== null) ||
      filters.value?.labels?.length > 0 ||
      filters.value.owner !== null ||
      filters.value.sharedWith !== null ||
      filters.value.dateRange !== null ||
      filters.value.colors.length > 0
    );
  });

  const clearFilters = () => {
    store.mutations.setState("filters", {
      search: "",
      sort: "created_at_desc",
      toggles: {
        hasFile: null,
        hasReminder: null,
        hasTask: null,
        pinned: null,
      },
      labels: [],
      owner: null,
      sharedWith: null,
      dateRange: null,
      colors: [],
    });
    updateQueryParams();
  };

  const updateQueryParams = () => {
    console.log("updateQueryParams", filters.value.search);
    const queryParams = {
      search: filters.value.search || undefined,
      sort:
        filters.value.sort !== "created_at_desc"
          ? filters.value.sort
          : undefined,
      hasFile:
        filters.value.toggles.hasFile !== null
          ? filters.value.toggles.hasFile
          : undefined,
      hasReminder:
        filters.value.toggles.hasReminder !== null
          ? filters.value.toggles.hasReminder
          : undefined,
      hasTask:
        filters.value.toggles.hasTask !== null
          ? filters.value.toggles.hasTask
          : undefined,
      pinned:
        filters.value.toggles.pinned !== null
          ? filters.value.toggles.pinned
          : undefined,
      labels: filters.value.labels?.length
        ? filters.value.labels?.join(",")
        : undefined,
      owner: filters.value.owner || undefined,
      sharedWith: filters.value.sharedWith || undefined,
      dateRange: filters.value.dateRange
        ? filters.value.dateRange
            .map((d) =>
              d && d > " " ? dateToShortUrlFormat(new Date(d)) : null
            )
            .join(",")
        : undefined,
      colors: filters.value.colors.length
        ? filters.value.colors.join(",")
        : undefined,
    };

    if (JSON.stringify(queryParams) !== JSON.stringify(lastFilters.value)) {
      router.replace({ query: queryParams });
    }
    lastFilters.value = queryParams;
    initialized.value = true;
    // fetchNotes();
  };

  const applyFiltersFromQuery = () => {
    const { query } = route;
    console.log("query.search", query.search);
    const newFilters = {
      search: query.search || "",
      sort: query.sort || "created_at_desc",
      toggles: {
        hasFile:
          query.hasFile === "true"
            ? true
            : query.hasFile === "false"
            ? false
            : null,
        hasReminder:
          query.hasReminder === "true"
            ? true
            : query.hasReminder === "false"
            ? false
            : null,
        hasTask:
          query.hasTask === "true"
            ? true
            : query.hasTask === "false"
            ? false
            : null,
        pinned:
          query.pinned === "true"
            ? true
            : query.pinned === "false"
            ? false
            : null,
      },
      labels: query.labels ? query.labels.split(",") : [],
      owner: query.owner || null,
      sharedWith: query.sharedWith || null,
      dateRange: query.dateRange
        ? query.dateRange.split(",").map((d) => shortUrlFormatToDate(d))
        : null,
      colors: query.colors ? query.colors.split(",") : [],
    };

    if (JSON.stringify(newFilters) !== JSON.stringify(filters.value)) {
      store.mutations.setState("filters", newFilters);
    }
    if (!initialized.value) {
      updateQueryParams();
    }
  };

  const debounceUpdateQueryParams = () => {
    updateQueryParams();
    // if (debounceTimeout.value) {
    //   clearTimeout(debounceTimeout.value);
    // }
    // debounceTimeout.value = setTimeout(() => {
    //   updateQueryParams();
    // }, 100);
  };

  watch(filters, debounceUpdateQueryParams, { deep: true });

  watch(
    route,
    () => {
      applyFiltersFromQuery();
    },
    { deep: true, immediate: true }
  );

  function updateFilter(key, value) {
    console.log("updateFilter", key);
    store.mutations.replaceModel("filters", key, value);
  }
  function setFiltersOpen(open) {
    showFilters.value = open;
  }
  return {
    filters,
    initialized,
    preloaded,
    preload,
    query: lastFilters,
    sortOptions,
    toggleOptions,
    labelOptions,
    ownerOptions,
    userContactOptions,
    colorOptions,
    cycleToggle,
    toggleClass,
    isAnyFilterActive,
    clearFilters,
    updateFilter,
    showFilters,
    setFiltersOpen,
  };
}
