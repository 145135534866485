import { RRule } from "rrule";
import { historyStack } from "../router/historyStore";
import { format } from "date-fns";
import { getTranslations } from "@/translations.js";
import store from "@/store";

export const generateRandomKey = (length = 16) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";

  for (let i = 0; i < length; i++) {
    result += characters[Math.floor(Math.random() * charactersLength)];
  }

  return result;
};
export const isImage = (url) => url?.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
export const isAudio = (url) => url?.match(/\.(mp3|wav|ogg)$/) != null;
export const isVideo = (url) => url?.match(/\.(mp4|webm|ogg|mov)$/) != null;
export const isPDF = (url) => url?.match(/\.pdf$/) != null; // Match files based on their extension

export const timezoneDate = (date) => {
  const selectedDate = new Date(date);

  // Assuming you want to include the user's local timezone offset
  const timezoneOffset = selectedDate.getTimezoneOffset(); // In minutes
  const timezoneOffsetInHours = timezoneOffset / 60; // Convert to hours

  // If you need to send the date with timezone information to the server:
  // const isoString = selectedDate.toISOString(); // This includes timezone as 'Z' (UTC)

  // You can format it as needed, e.g., sending a formatted string with timezone info to the server
  const formattedDateWithTimezone = `${
    selectedDate.toISOString().split("T")[0]
  }T00:00:00${timezoneOffsetInHours > 0 ? "-" : "+"}${String(
    Math.abs(timezoneOffsetInHours)
  ).padStart(2, "0")}:00`;
  return formattedDateWithTimezone;
};

export const handleChipsBlur = (event, model, field) => {
  const inputElement = event.target;
  const inputValue = inputElement.value.trim();

  if (inputValue) {
    if (!model[field]) {
      model[field] = [];
    }
    model[field].push(inputValue);
    inputElement.value = ""; // Clear the input field after adding the chip
  }
};

export const debounce = (fn, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
export function dateToShortUrlFormat(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export function dateToLongFormat(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 0 hours to 12 for AM/PM format

  return `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;
}

export function shortUrlFormatToDate(shortDate) {
  const [year, month, day] = shortDate.split("-").map(Number);
  return new Date(year, month - 1, day); // Months are zero-indexed
}

export function friendlyDate(date, short) {
  return format(date, !short ? "MMM dd yyyy hh:mm a" : "MMM dd yyyy");
}

export function generateRRule(options) {
  const freqMap = {
    1: RRule.DAILY, // 1: Daily
    2: RRule.WEEKLY, // 2: Weekly
    3: RRule.MONTHLY, // 3: Monthly
    4: RRule.YEARLY, // 4: Yearly
  };

  const rruleOptions = {
    freq: freqMap[options.frequency], // Use frequency from model
    interval: options.interval,
  };

  if (options.endCondition === "after") {
    rruleOptions.count = options.endAfterOccurrences;
  } else if (options.endCondition === "on") {
    rruleOptions.until = options.endOnDate;
  }

  // Handle specific day of the week for weekly frequency
  if (options.frequency === 2 && options.weekday) {
    rruleOptions.byweekday = options.weekday; // Assuming this is an array of weekdays (e.g., [RRule.MO, RRule.WE])
  }

  // Handle nth weekday (e.g., "3rd Tuesday of the month")
  if (options.frequency === 3 && options.nthWeekday && options.weekday) {
    rruleOptions.byweekday = [
      RRule.weekday(options.weekday).nth(options.nthWeekday),
    ]; // For example, nth(3) would be the 3rd occurrence of the specified weekday
  }

  // Handle specific day of the month for monthly frequency
  if (options.frequency === 3 && options.dayOfMonth) {
    rruleOptions.bymonthday = options.dayOfMonth;
  }

  // Handle specific month for yearly frequency
  if (options.frequency === 4 && options.monthOfYear) {
    rruleOptions.bymonth = options.monthOfYear;
  }

  const rule = new RRule(rruleOptions);
  return rule;
}

export function getRRuleText(rule) {
  const lang = store.state.preferences?.lang || "en";
  const spanishLocalization = {
    freq: {
      DAILY: "diariamente",
      WEEKLY: "semanalmente",
      MONTHLY: "mensualmente",
      YEARLY: "anualmente",
    },
    every: "cada",
    on: "el",
    onWeekday: "el",
    at: "a las",
    and: "y",
    except: "excepto",
    until: "hasta",
    // Add more translations as needed
  };
  return lang === "es"
    ? rule.toText(undefined, spanishLocalization)
    : rule.toText();
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getReminderDisplay = (reminder, params = {}) => {
  const translations = getTranslations();
  const { ruleOnly = false } = params;
  let ret = "";
  if (reminder.locationId) {
    ret = `${
      reminder.locationTriggerType === "arrive"
        ? translations.arrive
        : translations.leave
    } - ${reminder.location?.address.split(",", 5).join(" ")}`;
  } else if (reminder.event) {
    let rule;
    if (reminder.event?.frequency > 0) {
      rule = generateRRule({
        frequency: reminder.event?.frequency,
        interval: reminder.event?.interval,
        endAfterOccurrences: reminder.event?.count,
        endOnDate: reminder.event?.repeatEnd,
      });
    }

    ret = `${
      !ruleOnly ? format(reminder.event?.startTime, "E MMM dd,yy hh:mm aa") : ""
    } ${rule ? getRRuleText(rule) : ""}`;
  }
  return ret;
};

export const safeBackRoute = () => {
  const historyState = historyStack.value;
  console.log("historyState", historyState);
  if (historyState && historyState.length > 1) {
    return historyState[historyState.length - 2] || "/";
  }
  return "/";
};

export const isMobile = window.innerWidth <= 640;

export const getTemporaryCollaboratorDisplay = (
  collab = {},
  fallback = "Unknown"
) => {
  let display = fallback;
  const { name, email, phone, emails, phoneNumbers } = collab || {};
  if (name) {
    display = name;
  } else if (email) {
    display = email;
  } else if (phone) {
    display = phone;
  } else if (emails) {
    display = emails[0]?.email;
  } else if (phoneNumbers) {
    display = phoneNumbers[0].number;
  }
  return display;
};
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${
    sizes[i]
  }`;
};

export function setLocalStorageItem(key, value) {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error(`Error setting localStorage item "${key}":`, error);
  }
}

/**
 * Safely retrieves an item from localStorage.
 * @param {string} key - The key of the item to retrieve.
 * @returns {*} - The parsed value from localStorage, or null if not found or parsing fails.
 */
export function getLocalStorageItem(key) {
  try {
    const serializedValue = localStorage.getItem(key);
    return serializedValue !== null ? JSON.parse(serializedValue) : null;
  } catch (error) {
    console.error(`Error getting localStorage item "${key}":`, error);
    return null;
  }
}

/**
 * Removes an item from localStorage.
 * @param {string} key - The key of the item to remove.
 */
export function removeLocalStorageItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing localStorage item "${key}":`, error);
  }
}

export const isTouchDevice =
  "ontouchstart" in window || navigator.maxTouchPoints > 0;

export const encode = (input) => {
  try {
    return btoa(unescape(encodeURIComponent(input)));
  } catch (e) {
    console.error("Failed to encode:", e);
    return null;
  }
};

export const decode = (input) => {
  try {
    return decodeURIComponent(escape(atob(input)));
  } catch (e) {
    console.error("Failed to decode:", e);
    return null;
  }
};

/**
 * Generates keyword tokens from a string, omitting common stopwords.
 * @param {string} input - The input string to tokenize.
 * @returns {Array<string>} - An array of unique keyword tokens.
 */
export const generateKeywordTokens = (input) => {
  if (!input) {
    return null;
  }
  // List of common stopwords to omit
  const translations = getTranslations();
  const stopwords = new Set(translations.stopwords);

  // Normalize the input string: convert to lowercase and remove non-alphanumeric characters
  const normalized = input
    ?.toLowerCase() // Convert to lowercase
    ?.replace(/[^a-z0-9\s]/g, "") // Remove special characters (punctuation, etc.)
    .trim(); // Trim whitespace

  // Tokenize the string into words
  const tokens = normalized.split(/\s+/); // Split by whitespace

  // Filter out stopwords and return unique tokens
  const keywords = [...new Set(tokens.filter((word) => !stopwords.has(word)))];

  return keywords;
};

export const makeEmbed = (noteId, accessToken, userSecret) =>
  `<storatic-embed note-path="${noteId}/${accessToken}/${encodeURIComponent(
    userSecret
  )}"></storatic-embed><script async src="/embed.js"></script>`;
