import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";
import { useNoteStore, decryptNotesArray } from "./useNoteStore";

let timer = null; // Store the timer reference
const isStopped = ref(false);
export function useInvitationStore() {
  const isLoading = ref(false);
  const invites = computed(() => store.state.invites);
  const { lastQuery, fetchNotes } = useNoteStore();

  async function fetchInvites(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/invites", { params: query });
      const decrypted = await decryptNotesArray(response.data, {});
      store.mutations.setState("invites", decrypted);
    } catch (error) {
      console.error("Error fetching invites:", error);
    } finally {
      isLoading.value = false;
      // Check if the loop is not stopped before starting the next fetch
      if (!isStopped.value) {
        timer = setTimeout(() => fetchInvites(query), 60000); // 60 seconds
      }
    }
  }

  async function updateInvite(id, invitationData) {
    try {
      const response = await api.put(`/api/invites/${id}`, invitationData);
      console.log("response", response.data);
      if (response.data.isApproved || response.data.isDismissed) {
        const invites = store.state.invites.filter(
          (i) => i.id !== invitationData.id
        );
        store.mutations.setState("invites", invites);
        fetchNotes(lastQuery);
      }
    } catch (error) {
      console.error("Error updating invites:", error);
      throw error;
    }
  }

  return {
    invites,
    isLoading,
    fetchInvites,
    updateInvite,
    stopInviteLoop: () => {
      isStopped.value = true; // Provide a way to stop the fetching externally
      clearTimeout(timer); // Clear any pending timers
    },
    startInviteLoop: () => {
      if (isStopped.value) {
        isStopped.value = false;
        fetchInvites(); // Restart fetching if stopped
      }
    },
  };
}
